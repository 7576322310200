import React from 'react';
import { string } from 'prop-types';
import { Button, Box, Center, Text, useDisclosure, AspectRatio } from '@chakra-ui/react';

import getGrayScale from '@helpers/getGrayScale';
import WithAnimation from '@/components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import Content from './Content';

import { BG_LOVE_STORY } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import txt from './locales';

function LoveStory({ lang }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgPrimary">
      {/* Image Cover */}
      <Box padding="62px 32px">
        <Box position="relative" zIndex="2">
          <WithAnimation>
            <Center maxHeight="444px">
              <AspectRatio width="100%" maxW="300px" ratio={3 / 4.5}>
                <Image
                  borderRadius="16px"
                  boxShadow="2xl"
                  objectFit="cover"
                  objectPosition="center"
                  src={BG_LOVE_STORY}
                  {...getGrayScale()}
                />
              </AspectRatio>
            </Center>
          </WithAnimation>
        </Box>
        <Center>
          <Box maxW="300px" padding="0 12px">
            <WithAnimation>
              <Box
                boxShadow="2xl"
                bgColor="bgSecondary"
                bgSize="cover"
                borderRadius="16px 8px"
                marginTop="-40px"
                padding="42px 16px"
                paddingTop="62px"
                position="relative"
                textAlign="center"
                width="100%"
                zIndex="1"
              >
                <Text
                  marginTop="32px"
                  color="secondaryColorText"
                  fontSize="3xl"
                  fontFamily="heading"
                >
                  {txt.loveStory[lang]}
                </Text>
                <Text margin="16px 0 32px 0" color="alternativeDark">
                  {txt.description[lang]}
                </Text>
                <Button
                  bgColor="alternativeDark"
                  color="mainColorText"
                  fontFamily="bodyAlternative"
                  size="sm"
                  fontWeight="normal"
                  onClick={onOpen}
                  boxShadow="xl"
                  {...BUTTON_PROPS}
                >
                  {txt.loveStory[lang]}
                </Button>
              </Box>
            </WithAnimation>
          </Box>
        </Center>
      </Box>
      <Content onClose={onClose} visible={isOpen} />
    </Box>
  );
}

LoveStory.propTypes = {
  lang: string.isRequired,
};

export default LoveStory;
