import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Center,
} from '@chakra-ui/react';

import LoveStorySwapable from '@components/Common/LoveStorySwipe';

import { BUTTON_PROPS } from '@/constants/colors';
import txtWording from './locales';

function LoveStoryContent({ onClose, visible, lang }) {
  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent
        maxW="500px"
        bgColor="bgSecondary"
        bgSize="cover"
      >
        <DrawerCloseButton marginTop="12px" display="none" />
        <DrawerHeader />
        <DrawerBody>
          <LoveStorySwapable />
          <Center>
            <Button
              margin="16px 0 32px 0"
              letterSpacing="2px"
              textTransform="uppercase"
              onClick={onClose}
              size="sm"
              fontWeight="normal"
              marginTop="16px"
              boxShadow="xl"
              {...BUTTON_PROPS}
            >
              {txtWording.back[lang]}
            </Button>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

LoveStoryContent.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

LoveStoryContent.defaultProps = {
  lang: 'id',
};

export default LoveStoryContent;
