import get from '@invitato/helpers/dist/getLocale';

export default {
  back: get('Kembali', 'Back'),
  loveStory: get('Perjalanan Cinta', 'Our Love Story'),
  description: get(
    'Baca dan ikuti perjalanan cinta kami sebagai pasangan disini:',
    'Read and follow our love journey as a couple here:',
  ),
};
